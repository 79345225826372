import React , {useState, useEffect, useRef} from 'react';
import './App.css';
import { useChatGPTApi } from './utilities/api';
import { UIChatMessage } from './generated';
import { StringDecoder } from 'string_decoder';
import { getApiUri } from './utilities/data/api_configurations';

//Chat items will either be user queries (q), or answers from the model (a)


function App() {

  //Chat is stored in state
  const [chat, setChat] = useState(new Array<UIChatMessage>());

  const apiUrl = getApiUri().basePath || '/';
  
  // Example use of swagger api (currently not used)
  // const bottomRef = useRef<null | HTMLDivElement>(null);
  // const chatGPIApi = useChatGPTApi();

  //Getting a response from the API whenever a query (q) is sent.
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const latestQuery = chat[chat.length-1];
  //     if(latestQuery.role === "user") {
  //       const response = await chatGPIApi.useChatGPT({uIChatMessage: chat});
  //       setChat(response);
  //     }
  //   }
  //   fetchData().catch(console.error);
  // }, [chat, chatGPIApi]);

  // useEffect (() => {
  //   (document.getElementById("bottom") as HTMLDivElement).scrollIntoView({behavior: 'smooth'});
  // }, [chat]);

  useEffect(() => {
    if(chat.length > 0 && chat[chat.length-1].role === "user"){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(chat)
      };

      fetch(apiUrl, requestOptions)
        .then(response => response.json())
        .then(data => setChat([...chat,{role: "assistant", content: data.data}]));
    }
    (document.getElementById("bottom") as HTMLDivElement).scrollIntoView({behavior: 'smooth'});
  }, [chat])

  //Displaying text when submitted
  function newInput() {
    let input = (document.getElementById("Text-area") as HTMLInputElement).value;
    if (input === ""){
      return;
    }
    let newChatItem: UIChatMessage = {role: "user", content: input};
    (document.getElementById("Text-area") as HTMLInputElement).value = "";
    setChat([...chat,newChatItem]);
  }

  //Handle enter press on text input
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if(event.key === 'Enter') {
      newInput();
    }
  }

  function clear() {
    setChat([]);
  }
  
  return (
    <div className="App">
      <header className="App-header">
        Selune
      </header>
      <div className = "Conversation-area" id = "Message-area">
        {chat.map(m => {
          if(m.role != null){
            return(
              <p className = {m.role}>{m.content}</p>
            )
          }
        })}
        <p id = "bottom" className = "user"></p>
      </div>

      <div className = "Input-area">
        <input type= "text" id = "Text-area" placeholder = "Type a query..." onKeyDownCapture={(event) => handleKeyDown(event)}/>

        <button className="Submit-Button"onClick={() => newInput()}>Submit</button>
        <button className="Clear-Button" onClick={() => clear()}>Clear Conversation</button>
      </div>

    </div>
  );
}

export default App;
