import { Configuration, ConfigurationParameters } from "../../generated/runtime";
// import { User } from "oidc-client-ts";

import { assign } from "lodash";
// import { DefaultApi, OidcConfig } from "generated";

const apiConfigurations: ConfigurationParameters = {
    // accessToken: async () => {
    //     const user = await getUser();

    //     if (user === null) return "";

    //     return user.access_token;
    // },
};

// let oidoConfiguration: OidcConfig | null = null;

// async function getUser(): Promise<User | null> {
//     if (oidoConfiguration === null) {
//         const api = new DefaultApi(
//             new Configuration({
//                 basePath: apiConfigurations.basePath,
//             }),
//         );
//         oidoConfiguration = await api.oidcConfigGet();
//     }

//     const currentUser = sessionStorage.getItem(
//         `oidc.user:${oidoConfiguration?.authority ?? ""}:${oidoConfiguration?.clientId ?? ""}`,
//     );
//     if (currentUser === null) {
//         return null;
//     }
//     return User.fromStorageString(currentUser);
// }

export function setApiUri(uri: string) {
    apiConfigurations.basePath = uri;
}

export function getApiUri(config?: ConfigurationParameters): ConfigurationParameters {
    return assign(config || {}, apiConfigurations);
}
